export const fr = {
    general: {
        or: 'ou',
        email: 'Courriel',
        password: 'Mot de passe',
        confirmation: 'Confirmation',
        disconnect: 'Déconnexion',
        section: 'Section',
        administration: 'Administration',
        save: 'Sauvegarder',
        add: 'Ajouter',
        name: 'Nom',
        to: 'À',
        from: 'De',
        cancel: 'Annuler',
        close: 'fermer',
        greetings: 'Allô',
        'up-to-date': 'Vous êtes à jour! 🎉',
        done: 'Terminer',
        next: 'Suivant',
        'return-to-site': 'Retourner au site',
        other: 'Autre',
        delete: 'Supprimer',
        generic_delete_question: 'Êtes-vous certain de vouloir supprimer ceci?',
        generic_unassign_question: 'Êtes-vous certain désassigner ceci?',
        letsgo: 'Continuer',
        download_link: 'Lien de téléchargement',
        intro_message: '<b>OFFRE - Consultation gratuite 🥝</b><br>Tu peux profiter d\'un appel privilégié gratuit de 30 minutes avec un conseiller kiwiz avant de souscrire.<br>Direction, le clavardage (bulle rose en bas à droite) 🚀',
        good: 'Oh que oui!',
        bad: 'Pas pour moi!',
        ok: 'D\'accord!',
        back: 'Retour',
        previous: 'Précédent',
        welcome: 'Bienvenue! 🙏',
        saved: 'Sauvegarde effectuée!',
        'read-more': 'Lire plus',
        display_name_message: 'Comment t\'appelles-tu ?',
        here_you_go: 'Voilà!',
        a_cool_name: 'Ton nom génial',
        search: 'Recherche',
        next_step: 'Étape suivante',
        alright: 'D\'accord!',
        warning: 'Attention',
        continue: 'Continuer',
        selected: 'Sélectionné(s)',
        unassign: 'Désassigner',
        yes: 'Oui',
        no: 'Non',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        pay: 'Effectuer le paiement',
        help: 'Besoin d\'aide?',
        lets_go: 'C\'est parti!',
        welcome_person: 'Bienvenue',
        waiting_steps: 'Voici les étapes qui t\'attendent:',
        your_needs: 'Indiquer tes besoins',
        your_fav_vehicules: 'Choisir tes véhicules favoris',
        search_best: 'Lancer la recherche et recevoir les meilleures annonces',
        all_ressources: 'Accéder à toutes les ressources Kiwiz pour acheter en confiance (inspections,assurance,garanties,contrat d\'achat entreparticuliers,guides...)',
        logout: 'Déconnexion',
        loadMore: 'Défiler pour voir plus de véhicules.',
        language: 'Langue',
        french: 'Français',
        english: 'English',
    },
    firebase: {
        VERIFY_EMAIL: {
            title: 'Vérification du courriel',
            content: 'Ton courriel a été validé avec succès!'
        },
        PASSWORD_RESET: {
            title: 'Réinitialisation du mot de passe',
            content: 'Remplis les informations ci-dessous pour réinitialiser ton mot de passe 😉'
        }
    },
    errors: {
        disconnect: 'Vous avez été déconnecté par mesure de sécurité',
        forbidden: 'Vous n\'avez pas accès à cette ressource.',
        'missing-infos': 'Veuillez remplir toutes les informations demandées.',
        'wrong-password': 'Votre courriel/mot de passe est invalide.',
        'user-disabled': 'Votre compte a été désactivé.',
        'page-not-found': {
            title: 'Page introuvable',
            body: 'On ne semble pas trouver la page que vous voulez accéder. Vous pouvez tout de même utiliser nos outils!'
        },
        redirect: {
            website: 'Retourner à notre site web',
            search: 'Trouver ma voiture',
            inspection: 'Réserver une inspection',
        },
        'not-found': 'La ressource n\'a pas été trouvée',
        'invalid-email': 'Le courriel est invalide',
        'weak-password': 'Votre mot de passe doit contenir 6 caractères',
        'email-already-in-use': 'Compte déjà existant',
        error: 'Une erreur est survenue'
    },
    authentication: {
        policies: 'En continuant, vous acceptez nos <a href=\'https://kiwiz.ca/conditions-generales-kiwiz/\' target=\'_blank\'>Conditions d\'utilisation</a>',
        connect: 'Se connecter',
        connect_long: 'Déjà inscrit? <a>Connexion</a>',
        signup: 'S\'inscrire',
        signup_long: 'Pas encore inscrit? <a>Inscription</a>',
        tagline: 'N’achète pas de <span class=\'overline\'>citron</span>!',
        tagline_signup: 'Ton partenaire de <span class=\'overline\'>confiance</span> pour<br>acheter ta voiture usagée.',
        login: 'Connexion',
        'login-page': {
            connect_with_google: 'Se connecter avec Google',
            connect_with_facebook: 'Continuer avec Facebook'
        },
        'signup-page': {
            signup_with_google: 'Inscrivez-vous avec Google',
            signup_with_facebook: 'Continuer avec Facebook',
            password_confirmation: 'Confirmation'
        },
        confirmation: 'Merci de confirmer ton adresse courriel',
        'confirmation-page': {
            title: 'Merci de confirmer ton adresse courriel',
            'resend-code': 'Envoyer le lien à nouveau',
            message: 'Un lien t\'a été envoyé à ton adresse courriel'
        },
        forgot_password: 'J\'ai oublié mon mot de passe',
        password_modal: {
            title: 'Réinitialiser mon mot de passe',
            confirm: 'Réinitialiser',
            email: 'Courriel',
            message: 'Un lien t\'a été envoyé à ton adresse courriel'
        },
        password_reset_successful: 'Ton mot de passe a été modifié avec succès!',
        new_code_sent: 'Un nouveau code a été envoyé'
    },
    client: {
        purchased_content: '<b>Félicitations 🎉</b> <br> Acheter son véhicule est une aventure importante! Merci pour ta confiance et ton aide dans la guerre des citrons ! 🍋',
        suggestions: {
            needs: 'Selon tes besoins, voici les voitures qu\'on te suggère :',
            favorites: 'Ma liste de véhicules favoris',
            important: 'Ajoute des véhicules favoris dans cette liste pour qu\'on parte à la recherche des meilleures affaires actuellement sur le marché. Pas de panique : tu peux changer et compléter cette liste avec d\'autres modèles de voitures à l\'étape suivante',
            add_car: 'Ajoute un véhicule'
        },
        insurance: {
            title: 'Assurance'
        },
        pro: {
            title: 'Pour avoir accès à ce contenu, tu dois être membre pro 👑',
            message: 'Pour y avoir accès, continue l\'étape à laquelle tu étais',
        },
        menu: {
            kiwiz: 'Mes kiwiz',
            guides: 'Ressources',
            general: 'Accueil',
            admin: 'DASHBOARD ADMIN',
            sequence: 'Mes étapes d\'achat',
            insurance: 'Assurance',
            preferences: 'Mes préférences',
            favorites: 'Ma sélection'
        },
        informations: {
            get_your_kiwiz: 'Je découvre les meilleures annonces de voitures sur le marché 🎉',
            get_kiwiz_message: 'Ton <b>conseiller Kiwiz</b> recherche actuellement <br> <b>tes potentiels kiwiz (meilleures annonces)</b> disponibles 🥝 <br> Le délai maximal est de 48 heures (jours ouvrables)',
            get_kiwiz_message_ads: 'Un expert Kiwiz est sorti victorieux de sa guerre aux citrons!',
            see_my_kiwiz: 'Voir mes kiwiz',
            continue_my_sequence: 'Continuer mon aventure'
        },
        guides: {
            title: 'Ressources',
            no_guides: 'Aucune ressource n\'est disponible pour le moment',
            consult: 'Consulter l\'article',
            download_pdf: 'Télécharger le pdf'
        },
        preferences: {
            modify_criteria: 'Modifie tes critères de recherche à tout moment pour avoir les meilleurs annonces possibles !',
            consideration: ' Ceux-ci seront pris en considération dans la prochaine recherche d\'annonces que tu lanceras',
            vehicle: 'Véhicule 🚗',
            max_price: 'Prix maximal',
            max_mileage: 'Kilométrage maximal',
            min_year: 'Année minimale',
            max_vehicle_age: '  Âge maximal',
            body_work: 'Type de carroserie',
            seller: 'Vendeur',
            search: 'Recherche',
            postal_code: 'Code Postal',
            manual: 'Manuelle',
            automatic: 'Automatique',
            no_preference: 'Pas de préférences',
            motor_skills: 'Motricité',
            traction_integral: 'Traction intégrale',
            annual_mileage: 'Kilométrage annuel moyen',
            my_priorities: 'Mes priorités',
            criteria: 'Critères',
        },
        "model_algo": {
            "title_part": {
                "title": "Suggestions de modèles",
                "welcome_message": "Bienvenue dans la section Suggestion de modèles! ",
                "instructions": "Choisis tes critères et nous te suggérerons les meilleurs véhicules! ",
                "ad_encouragement": "Tu pourras considérer ceux-ci dans la prochaine recherche d'annonces de véhicule que tu lanceras. ",
                "dont_fill_all": "* Psst! Tu n'es pas obligé de tout remplir! ",
                "return_website": "Retour au site web"
            },
            "typesList": {
                "sedan": "Berline",
                "convertible": "Cabriolet",
                "pickup": "Camion",
                "van": "Fourgon",
                "coupe": "Coupé",
                "hatchback": "Hayon",
                "minivan": "Fourgonnette",
                "wagon": "Familiale",
                "suv": "VUS",
                "roadster": "Roadster",
                no_preference: 'Pas de préférence',
            },
            "priorities": "Priorités",
            "prioritiesList": {
                "fuel_consumption": "Faible consommation",
                "driving": "Plaisir de conduite",
                "interior": "Confort",
                "reliability": "Fiabilité",
                "safety": "Sécurité"
            },
            "requirement": "Exigences",
            "equipment": "Équipements essentiels",
            "equipmentList": {
                "sunroof": "Toit ouvrant",
                "heated_front_seats": "Sièges avant chauffants",
                "gps": "GPS",
                "aux": "Entrée audio auxiliaire",
                "cd_player": "Lecteur CD",
                "apple_carplay": "Compatibilité Apple CarPlay",
                "lane_departure_warning": "Avertissement de sortie de voie",
                "siriusxm": "Sirius XM",
                "bluetooth": "Audio Bluetooth",
                "remote_engine_start": "Démarrage à distance",
                "adaptive cruise control": "Régulateur de vitesse adaptatif",
                "forward_collision_warning": "Avertissement de collision avant",
                "emergency_braking": "Freinage d'urgence autonome",
                "usbs": "Ports USB avant",
                "bindspot_detection": "Détection des angles morts",
                no_preference: 'Pas de préférence',
            },
            "autonomy": "Autonomie de la batterie (km)",
            "min_year": "Année minimale",
            "max_year": "Année maximale",
            "min_passengers": "Nombre de passagers minimal",
            "engine": "Moteur",
            "engineList": {
                "electric": "Électrique",
                "hybrid": "Hybride",
                "gas": "Essence",
                no_preference: 'Pas de préférence',
            },
            "transmissionsList": {
                "manuel": "Manuelle",
                "automatic": "Automatique"
            },
            "drivetrain": "Motricité",
            "drivetrainList": {
                "traction": "Traction",
                "propulsion": "Propulsion",
                "integral": "Traction intégrale",
                no_preference: 'Pas de préférence',
            },
            "fuel_type": "Type de carburant",
            "fuelList": {
                "regular": "Régulière",
                "regular_e85": "Régulière E85",
                "super": "Super",
                "diesel": "Diesel",
                "premium": "Premium",
                no_preference: 'Pas de préférence',
            },
            "your_budget": "Ton budget",
            "result_box": {
                "results": "Résultats",
                "description": "Clique ici pour voir tes futurs Kiwiz potentiels! 🥝",
                "button": "Clique ici pour voir les résultats",
                "no_results": "Désolé, aucun résultat ne correspond à tes critères"
            },
            "results": {
                "make": "MARQUE",
                "model": "MODÈLE",
                "year": "ANNÉE",
                "score": "SCORE",
                "see_car": "Voir le véhicule"
            }
        },
        ads: {
            title: 'Tes annonces de kiwiz',
            see_ad: 'Consulter l\'annonce',
            back: 'Retour à mes annonces',
            vehicle_description: 'Description du véhicule',
            expert_note: 'Notes du conseiller Kiwiz',
            client_note: 'Notes personnelles sur le véhicule',
            drivetrain: 'Motricité',
            fuel: 'Carburant',
            consumption: 'Consommation',
            maintenance: 'Maintenance (à venir)',
            purchased: 'J\'ai acheté ! 🥝',
            view_on_site: 'Voir l\'annonce',
            vehicle_informations: 'Informations sur le véhicule',
            make: 'Marque',
            model: 'Modèle',
            year: 'Année',
            version: 'Version',
            mileage: 'Odomètre',
            price: 'Prix de vente',
            recommended_drivetrain: 'Motorisation conseillée',
            ratings: 'Notes de nos experts',
            pricing: 'Prix détaillés',
            taxes: 'Taxes',
            warranty: 'Garanties* 36mois/60000km',
            licenses: 'Permis et immatriculation',
            insurance: 'Assurance',
            buying_budget: 'Coûts à l\'achat',
            yearly_budget: 'Coûts annuels',
            show_warranty: 'Inclure les garanties',
            inspect: 'Faire inspecter 🧑‍🔧',
            my_kiwiz: 'Mes Kiwiz',
            search_kiwiz: 'La pêche aux kiwiz a été bonne ! 🥝🚘',
            research_all_platforms: 'On a pris en considération tes favoris, tes préférences et tes critères, et on a recherché sur toutes les plateformes  les meilleures affaires actuellement sur le marché (notre technologie permet de sélectionner uniquement des voitures à un prix intéressant 💡)',
            discover_announcement: 'Découvre les annonces trouvées, et fais vite, elles ne seront bientôt plus disponibles !🌟✅',
            hope_its_kiwiz: 'On espère que ce sera un kiwiz ! 🤞',
            kiwiz_ideal: '(Pour rappel, un kiwiz est une voiture d\'occasion idéale sans défauts cachés, achetée en toute confiance !🙌)',
            next_step: 'Prochaines étapes:',
            call_owner: 'Appeler le propriétaire',
            guide_available: '(guide disponible dans l\'onglet "Ressources")',
            do: 'Faire une',
            mecanical_inspection: 'inspection mécanique',
            assure_its_kiwiz: 'pour s\'assurer que c\'est un kiwiz et non pas un citron... (onglet "Inspections\)',
            continue: ' Continuer',
            delete_announcement: 'Supprimer l\'annonce',
            announcement_of: 'Annonces de ',
            kiwiz_ideal_meskiwiz: 'Pour rappel, un kiwiz est une voiture d\'occasion idéale sans défaut caché, achetée en toute confiance !🙌',
            selected_announcement: 'Retrouve ci-dessous tes annonces selectionnées et classées selon tes modeles favoris.🚘',
            my_models: 'Mes modèles',
            fav_model: 'Pour recevoir les meilleurs annonces disponibles sur le marché, tu dois d\'abord nous renseigner tes 3 modèles favoris sur la page',
            general: '"Acceuil" ',
            search_best_announcement: 'et cliquer sur "Rechercher les meilleures annonces".',
            best_deals: 'Ensuite nous partions à la recherche des meilleurs affaires.🚘',
            add_favorite: 'Ajouter',
            remove_favorite: 'Retirer',
            kiwiz_opinion: 'L\'opinion de Kiwiz',
            technical_sheet: 'Fiche technique',
            price_and_kilometers: 'Prix et kilométrages moyens (par année)',
            average_price: 'Prix moyen',
            average_kilometers: 'Kilométrage moyen',
            remove_from_favorites: 'Enlever des favoris',
            see_form: 'Voir la fiche',
            submission_here: '(fais ta soumission ici)'
        },
        inspections: {
            title: 'Réserver une inspection',
            basic_inspection: 'Inspection de base',
            complete_inspection: 'Inspection complète',
            pick_inspection: 'Choisis ci-dessous l\'inspection que tu souhaites effectuer.',
            inspection_carried_out: 'Inspections réalisées ',
            find_under_inspection_carried_out: 'Retrouve ci-dessous les inspections que tu as réalisées',
            car: 'Voiture',

            steps: {
                header: 'L\'inspection Kiwiz, en 4 étapes simples',
                first_step: '1- Souscris à l\'inspection ici ',
                first_desc: 'Le montant de l\'inspection te sera prélevé afin de la réserver. Si pour quelconque raison l\'inspection ne peut pas avoir lieu, tu seras remboursé(e) intégralement.',
                second_step: '2- Kiwiz se charge d\'organiser un rendez-vous entre le vendeur et l\'inspecteur Kiwiz  dans les plus brefs délais',
                second_desc: 'Tu n\'as rien à faire 🤩',
                third_step: '3- L\'inspecteur Kiwiz se déplace et inspecte le véhicule rigoureusement, sur 170 points',
                third_desc: 'Tu n\'as même pas besoin d\'être avec lui sur place! Si tu choisis l\'inspection "Sans pépins", l\'inspecteur Kiwiz négociera le véhicule à ta place, selon l\'état.',
                fourth_step: '4- Tu reçois le rapport d\'inspection digital qui t\'indique si l\'inspecteur estime que l\'auto est une bonne affaire ou non',
                fourth_desc: 'Adieu les arnaques 💣🙌'
            },
            question_mark: 'Tu ne sais pas quelle inspection choisir ?',
            find_out_more: 'En savoir plus',
            tabs: {
                inspections_report: 'Mes rapports',
                subscribe: 'Réserver',
                no_report: 'Tu n\'as pas encore de rapport d\'inspection'
            },
            mandate_page: {
                clientName: "Nom du client",
                mandate: "Mandats",
                createMandate: "Créer mandat",
                make_model_year: "Marque/Modèle/Année",
                ad_city : 'Ville',
                sellerName: "Vendeur",
                informations: "Informations",
                url: "Coordonnées du vendeur",
                postal_code: "Code postal",
                status: "Statut",
                available: "Disponible",
                confirmed: "Confirmé",
                accepted: "Accepté",
                paid: "Payé",
                finished: "Terminé",
                started: "Commencé/en cours",
                accept: "Accepter",
                confirm: "Confirmer",
                debut: "Débuter l'inspection",
                made_on: "réalisée le ",
                completedInspection: "Inspection réalisée",
                date: "Date de l'inspection",
                mandate_inspect: "Mandat d'inspection",
                communicate: "Communiquez avec le vendeur !",
                time_and_date: "Trouver une date et une heure à laquelle l'inspection peut être effectuée.",
                enter_date: "Entrer la date de l'inspection",
                enter_time: "Entrer l'heure de l'inspection",
                open: "Voir détails",
                infoModal: {
                    title: "Informations",
                    inspectionDate: "Inspection prévue le ",
                    sellerInfo: "Vendeur",
                    contactInfo: "Coordonnées: ",
                    adCity: "Ville: ",
                    customerInfo: "Client",
                    lastName: "Nom: ",
                    firstName: "Prénom: ",
                    customerEmail: "Courriel: ",
                    customerPhone: "Cellulaire: ",
                    negoRequest: "Demande de négociation: ",
                    extraRequest: "Demande supplémentaire: ",
                    inspectorCallRequest : "Requete d'appel: ",
                    wantInspectorCall: "Ce client souhaite recevoir un appel après l'inspection.",
                    callNotNecessary: "Ce client ne souhaite pas recevoir un appel après l'inspection.",
                    goToUrl: "Voir l'annonce",
                    components : "Composants",
                    component : "Composant:",
                    vehicleDetails : "Details de la voiture",
                    descriptionIssue : "Description du problème:",
                    totalPrice : "Prix total"
                }
            },
            reports: {
                title: 'Mes rapports',
                inspection_details: {
                    all_rights: "KIWIZ © Tous droits réservés.",
                    tab_synthesis: {
                        synthesis: 'Résumé',
                        information_on_vehicle: 'INFORMATIONS',
                        brand: 'Marque',
                        model: 'Modèle',
                        version: "Version",
                        year: 'Année',
                        mileage: 'Odomètre',
                        date_of_inspection: 'Date de l\'inspection',
                        time_of_inspection: 'Heure de l\'inspection',
                        city: 'Ville',
                        opinion_on_vehicle: 'L\'avis de Kiwiz',
                        recommended: 'Conseillé à l\'achat',
                        general_comment_inspector: 'Commentaires',
                        inspector_kiwiz: 'INSPECTEUR:',
                        first_paragraph: 'Je suis concepteur mécanique, je travaille dans la sécurisation machine pour l\'industrie et j\'ai étudié en génie mécanique.',
                        second_paragraph: 'Je suis passionné par tout ce qui roule depuis mon enfance : voitures, camions, motos... je passe une bonne partie de mon temps libre à étoffer mes connaissances en automobile et en sport mécanique!',
                        third_paragraph: 'Si je me suis suis lancé dans l\'aventure kiwiz c\'est pour partager mon expertise en ingénierie mécanique et accompagner en toute transparence les clients dans le bon choix de leur future voiture.',
                        cost_of_repairing: 'Observations',
                        required_to_repair: 'Éléments',
                        potential_to_repair: 'Potentielles réparations',
                        problem: 'Observations',
                        cost: 'Coûts',
                        vin: 'NIV',
                        recommendation: "Recommandations",
                        color: 'Couleur du véhicule',
                        fuel: 'Type d\'énergie',
                        firstHand: "Véhicule de première main",
                        finalOffer: "Offre finale négociée"
                    },
                    tab_details: {
                        exterior: "Extérieur",
                        road_test: 'ESSAI ROUTIER',
                        details: 'DÉTAILS',
                        elements: 'Éléments',
                        tires: 'Pneus',
                        comment: 'Commentaires',
                        not_verified: 'Non vérifié(e)',
                        linear_acceleration: 'Accélération constante',
                        temperature: 'Montée en température et en pression',
                        driving_line: 'Tenue de route',
                        function_direction: 'Direction',
                        easy_shifting: 'Passages de vitesses',
                        clutch_grip: 'Grip de l\'embrayage',
                        running_gear: 'Trains roulants',
                        motor_vibration: 'Absence de vibrations et de bruits anormaux',
                        brake_noises: 'Freinage ',
                        esthetic: 'ESTHÉTIQUE',
                        equipment: 'Équipements',
                        keys: 'Nombre de clés incluses: ',
                        manual: 'Manuel inclus: ',
                        floor_mat: 'Tapis de sol inclus: ',
                        floor_mat_summer: 'État tapis d\'été: ',
                        floor_mat_winter: 'État des tapis d\'hiver: ',
                        trunk_equipment: 'Équipement(s) présent(s): ',
                        additional_equipment: 'Équipement(s) supplémentaire(s) inclus: ',
                        interior: 'Intérieur',
                        cleanliness: 'Propreté générale',
                        odor: 'Odeur',
                        stains: "de taches/déchirures",
                        roof_stains: 'Ciel de toit',
                        seat_stains_front: 'Sièges avants',
                        seat_stains_back: 'Banquette arrière',
                        trunk_stains: 'Coffre',
                        trunk_rust: 'de rouille dans le fond du coffre',
                        floor: 'Plancher',
                        bodywork: 'Carosserie',
                        bumper: 'Pare-chocs',
                        windshieldwiper: 'Essuie-glaces',
                        optical_block: 'Blocs optiques',
                        mirror: 'Miroirs',
                        wing: 'Ailes',
                        door: 'Portes',
                        lower_body: 'Bas de caisses',
                        rim: 'Jantes',
                        hood: 'Capot',
                        trunk: 'Coffre',
                        roof: 'Toit/capote',
                        functional: 'FONCTIONNEL',
                        safety_belt: 'Ceintures de sécurité',
                        front_seat_adjustment: 'Réglages des sièges avants',
                        audio_system: 'Système audio',
                        dashboard: 'Ordinateur de bord',
                        front_windshield_wiper: 'Essuie-glaces avant',
                        rear_windshield_wiper: 'Essuie-glaces arrière',
                        air_conditioning: 'Climatisation',
                        electric_windows: 'Vitres électriques',
                        driver_mirror: 'Miroir conducteur',
                        interior_mirror: 'Miroir intérieur',
                        passenger_mirror: 'Miroir passager',
                        glove_box: 'Boite à gants',
                        trunk_opening: 'Ouverture du coffre',
                        additional_options: 'Option(s) supplémentaire(s): ',
                        lights: 'Lumières',
                        front: 'Avant',
                        rear: 'Arrière',
                        front_passing_lamps: 'Ampoules de croisement',
                        front_main_beam: 'Ampoules de routes',
                        front_head_lights: 'Plein-phares',
                        fog_lights: 'Antibrouillard',
                        turn_signal: 'Clignotants',
                        interior_light: 'Éclairages intérieur',
                        position_lights: 'Ampoules de position',
                        brake_lights: 'Ampoules de freinage',
                        reversing_lights: 'Ampoules de marche arrière',
                        license_plate_lights: 'Ampoules de la plaque d\'immatriculation',
                        windshield: 'Pare-brise avant',
                        backwindshield: 'Lunette arrière',
                        front_driver: 'Devant conducteur: ',
                        rear_driver: 'Derrière conducteur: ',
                        front_passenger: 'Devant passager: ',
                        rear_passenger: 'Derrière passager: ',
                        door_opening: 'Ouverture des portes',
                        tire_model_brand: 'Marque et modèle du pneu: ',
                        type_of_tire: 'Type de pneu: ',
                        brake: 'Système de Freinage',
                        tire_wear: 'Usure',
                        tire_pressure: 'Pression',
                        tire_years: "Année des pneus: ",
                        tire_dimension: 'Dimension des pneus: ',
                        engine: 'COMPARTIMENT MOTEUR',
                        obd_scanner: 'Témoins lumineux',
                        accesory_belt: 'Courroie d\'accessoire',
                        air_filters: 'État du filtre à air',
                        windshield_washer_fluid: 'Niveau du liquide lave-glaces',
                        brake_fluid: 'Niveau du liquide de freinage',
                        powerSteering_fluid: 'Niveau du liquide de servodirection',
                        coolant_fluid: 'Niveau du liquide de refroidissement',
                        engine_oil_level: 'Niveau d\'huile moteur',
                        front_driver_side: 'Avant côté conducteur',
                        front_passenger_side: 'Avant côté passager',
                        rear_driver_side: 'Arrière côté conducteur',
                        rear_passenger_side: 'Arrière côté passager',
                        used: 'Usé, encore bon pour 1-2 saisons',
                        change: 'À changer',
                        new: 'Neuf',
                        front_lights: "Lumières avant",
                        rear_lights: "Lumières arrière",
                        none: "Aucun",
                        windshieldwiper_functional: "Essuie-glaces",
                        mirror_functional: "Miroirs",
                        wheels: "Roues",
                        remote_opening: "Ouverture à distance: ",
                        plastics_trim: "Plastiques et garnitures",
                        horn: "Klaxon",
                        door_locking: "Verrouillage des portes",
                        fuel_door: "Trappe à essence"

                    },
                    tab_photos: {
                        general: 'Général',
                        defects: 'Défauts'
                    },
                },
            },
            purchase: {
                firstStepTitle: 'Type d\'inspection',
                secondStepTitle: 'Vérifier la disponibilité',
                make: 'Marque',
                model: 'Modèle',
                year: 'Année',
                mileage: 'Kilométrage',
                outsideZoneCheckbox: 'Je confirme avoir communiqué avec l\'équipe Kiwiz pour vérifier la possibilité de réaliser une inspection dans cette zone.',
                carfaxUrl: 'Carfax',
                VIN: 'NIV',
                sellerTypes: {
                    title: 'Type de vendeur',
                    dealership: 'Concessionnaire',
                    private: 'Particulier',
                    owner: 'Je suis le propriétaire de la voiture',
                },
                purchaseType: "Type d'achat",
                sellerCoordinates: 'Coordonnées du vendeur',
                phoneNumber: 'Numéro de téléphone',
                sellerName: 'Nom du vendeur',
                sellerCity: 'Ville du vendeur',
                dealerName: 'Nom du concessionnaire',
                adUrl: 'URL de l\'annonce',
                zipCode: 'Code Postal',
                fullAddress : 'Adresse',
                price: 'Prix affiché',
                buyerInfo: 'Coordonnées personnelles',
                firstName: 'Prénom',
                lastName: 'Nom',
                email: 'Adresse courriel',
                callAfterInspection: 'Souhaitez-vous qu\'on vous appelle suite à l\'inspection?',
                notes: 'Notes supplémentaires',
                terms: 'En appuyant sur le bouton réserver ci-dessous, ',
                bookInspection: 'Réserver mon inspection',
                payment: 'Paiement',
                bookingConfirmation: 'Félicitations, ta demande d\'inspection est validée. Il ne te reste plus qu\'à la confirmer en passant au paiement.',
                pay: 'Payer',
                back: 'Retour',
                next: 'Continuer',
                summary: 'Récapitulatif de l\'achat : ',
                availableZone: 'Un ou plusieurs inspecteurs couvrent cette zone.',
                unavailableZone: "Cette zone n'est pas couverte par un de nos inspecteurs.",
                leadOrigin: {
                    title: 'Comment avez-vous entendu parler de Kiwiz? (l\'équipe marketing vous remercie pour votre aide 🤗)',
                    placeholder: 'Choisir une option',
                    search: 'Recherche sur l\'Internet',
                    marketing: 'Publicité sur Facebook ou Instagram',
                    kijiji: 'Kijiji Autos',
                    media: 'Dans la presse, radio, etc.',
                    blog: 'Article de blog ou vidéo YouTube',
                    mouth: 'Bouche à oreille',
                    other: {
                        title: 'Autre',
                        placeholder: 'Précisez',
                    },
                },
                carfax: {
                    title: 'URL du rapport Carfax',
                    mainText: 'Bien que le rapport Carfax soit optionnel, il permettra à l\'inspecteur de vérifier l\'historique du véhicule et sa cohérence avec l\'état des composants du véhicule inspecté. Plus l\'inspecteur dispose d\'informations, plus la recommandation sera précise.',
                    subText: 'Si ce n\'est pas fait, vous pouvez |acheter le CARFAX|. Assurez-vous de le recevoir d\'une source fiable. Seul le lien du rapport CARFAX peut être partagé, tout autre document (photo, PDF, etc.) n\'est pas valide.',
                },
                inspectionCreditCode: {
                    title: 'Code de crédit d\'inspection',
                    redeem: 'Utiliser le crédit',
                    error: {
                        title: 'Ce code ',
                        'Not found': 'n\'existe pas',
                        'Already used': 'a déjà été utilisé',
                    },
                    confirmation: {
                        title: 'Votre inspection est confirmée',
                        message: 'Vous recevrez une confirmation par courriel sous peu.'
                    }
                },
                customerLanguage: 'Langue du client',
                createMandate: 'Créer le mandat',
                clientOwner: 'La voiture est celle du client.',
                type: {
                    placeholder: 'Sélectionner le type d\'achat',
                    //complete: 'Inspection régulière',
                    //iA: 'Industrielle Alliance',
                    b2b: 'B2B',
                    b2c: 'B2C',
                }
            },
        },
        sequence: {
            rating: {
                question: 'Ajouter ce modèle dans ma liste de voitures favorites ? '
            }
        },
        homepage: {
            logo_subtitle: 'Ton partenaire de confiance pour acheter ta voiture usagée',
            tiles: {
                title: 'Bienvenue',
                search: {
                    title: 'Recherche',
                    description: 'Sélectionne tes modèles favoris'
                },
                profile: {
                    personal_information: 'Information personelles',
                    research_criteria: 'Critères',
                    preferences: 'Préfèrences',
                    save: 'Sauvegarder',
                    my_profile: 'Mon profil',

                    first_tab: {
                        first_name: 'Prénom',
                        last_name: 'Nom',
                        email: 'Courriel'
                    },

                    second_tab: {
                        search_radius: 'Rayon de recherche',
                        type_of_occasion: 'Type d\'occasion',
                        go_study: 'Aller étudier',
                        go_work: 'Aller travailler',
                        travel: 'Voyager',
                        professional_use: 'Usage Professionel(Eva,Uber,Etc.)',
                        drifting: 'Déraper sur le parking du Maxi',
                        other: 'Autre',
                        fuel: 'Carburant',
                        electric: 'Électrique',
                        hybrid: 'Hybride(électrique et essence)',
                        gasoline: 'Essence',
                        no_preference: 'Pas de préférence',
                        number_of_passenger: 'Nombre de passager',
                    },
                },
                ads: {
                    title: 'Mes annonces',
                    description: 'Consulte tes annonces'
                },
                criterias: {
                    title: 'Mes critères',
                    description: 'Modifie à tout moment tes critères de recherche'
                },
                insurance: {
                    title: 'Assurance',
                    description: 'Compare les assurances et trouve la moins chère'
                },
                ressources: {
                    title: 'Ressources',
                    description: 'Des guides pour t\'aider à chaque étape'
                },
                inspection: {
                    title: 'Inspection',
                    description: 'Assure-toi du bon état de ta future voiture'
                },
            },
        },
    },
    sequence: {
        sequence: 'En cours',
        home: 'Tableau de bord',
        help: 'J\'ai besoin d\'aide',
        completed: 'complété',
        vehicle_suggestion: 'Tu dois avoir donné une note à chacun des véhicules avant de continuer 😉'
    },
    admin: {
        menu: {
            general: 'Général',
            ads: 'Annonces',
            guides: 'Guides',
            users: 'Usagers',
            sequences: 'Séquences',
            settings: 'Paramètres',
            vehicles: 'Véhicules',
            todo: 'Liste \'À faire\'',
            ressources: 'Ressources',
            summary: 'Sommaire',
        },
        general: {
            'waiting-list': {
                title: {
                    waiting: 'En attente de suggestions',
                    'waiting/ads': 'En attente d\'annonces',
                    'waiting/purchase': 'En achat',
                    inprogress: 'En cours'
                }
            },
            'total-users': 'Clients enregistrés'
        },
        inspections_page: {
            tabs: {
                newInspectionTable: 'Nouvelles inspections',
                ongoingInspectionTable: "Inspections programmées",
                completedInspectionTable: "Inspections terminées",
                incompleteInspectionTable: "Inspections incomplètes",
                problemInspectionTable: "Problèmes signalés"
            },
            assign: "Assigner",
            remove: "Retirer",
            problem: 'Problème',
            assignToInspector: "Voulez-vous assigner l'inspection à cet inspecteur: ",
            yes: "Oui",
            no: "Non",
            assignmentTitle: "Assigner à un inspecteur",
            createMandate: "Créer mandat d'inspection",
            daysOff: "jour de congé",
            problemTable: {
                majorProblem: "Problème signalé",
                problem: "Problème(s)",
                noResponse: "Le vendeur ne répond pas",
                contactLater: "Le vendeur contactera l\'inspecteur plus tard",
                rescheduleInspection: "Le vendeur souhaite déplacer/reprogrammer l\'inspection",
                alreadySold: 'La voiture est déjà vendue',                                      
                sellerRefusal: 'Le vendeur refuse de faire inspecter la voiture',               
                clientRequest: 'Vous l\'avez demandé',                                          
            },
            searchBar : {
                clientFirstName : "Prénom du client",
                clientLastName : "Nom du client",
                email : "Courriel du client",
                inspectorFirstName : "Prénom de l'inspecteur",
                year : "Année",
                search : "Rechercher",
                toggle : "Filtre(s)"
            },
            snackBar: {
                inspectionRemoved: "Inspection removed",
                searchStarted: "Search started",
                inspectionAssigned: "Inspection assigned",

            },
        },
        reusable_modal: {
            enter_date_days_off: "Entrez la date de votre jour de congé",
            back: "Retour",
            confirm: "Confirmer"
        },

        sequence: {
            title: 'Gestion des séquences',
            delete: 'Êtes-vous certain de vouloir supprimer cette séquence?',
            new: 'Nouvelle séquence',
            show_done_button: 'Montrer le bouton \'Suivant\'',
            notify_admins: 'Envoyer une notification aux administrateurs',
            waiting_on_expert_label: 'Texte pour l\'attente envers un expert kiwiz',
            step: {
                'vehicle-suggestions': 'Suggestion de véhicules',
                text: 'Texte',
                payment: 'Paiement'
            },
            hasEndModal: 'Montrer le popup final',
            hasInitialModal: 'Montrer le popup initial'
        },
        vehicles: {
            title: 'Véhicules',
            makes: 'Marques',
            types: 'Types',
            years: 'Années',
            modal: {
                title: 'Modification du véhicule',
                infos: 'INFOS',
                form: 'FICHE',
                make: 'Marque',
                model: 'Modèle',
                type: 'Type',
                drivetrain: 'Motricité',
                fuel: 'Carburant',
                consumption: 'Consommation',
                maintenance: 'Entretiens',
                origin: 'Origine',
                warranty: 'Garantie',
                resell_value: 'Valeur de revente',
                category: 'Catégorie',
                advantages: 'Avantages',
                disadvantages: 'Inconvénients',
                image: 'Image',
                thumbnail: 'Thumbnail',
                images: 'Images',
                selection: 'Sélection',
                search: 'Recherche',
                reliability: 'Fiabilité',
                money_value: 'Rapport qualité prix',
                money_value_short: 'Prix',
                comfort: 'Confort',
                fun_factor: 'Plaisir de conduite',
                fun_factor_short: 'Plaisir',
                security: 'Securité',
                cargo_space: 'Espace de chargement',
                recalls: 'Rappels',
                recommended_drivetrain: 'Motorisation conseillée',
                year_min: 'Année (min)',
                year_max: 'Année (max)'
            }
        },
        users: {
            title: 'Usagers',
            display_name: 'Nom',
            email: 'Courriel',
            progression: 'Progression',
            creation: 'Inscription',
            lastSignInTime: 'Dernière connexion',
            modal: {
                title: 'Information de l\'usager',
                step: 'Étape',
                completed_true: 'Terminée',
                completed_false: 'En cours',
                suggest_vehicles: 'Suggérer des véhicules',
                suggestions: {
                    title: 'Suggestion de véhicules'
                },
                close_entry: 'Fermer l\'entrée',
                close_entry_question: 'Êtes-vous certain de vouloir fermer cette entrée?',
                reset_entry: 'Réinitialiser l\'entrée',
                reset_entry_question: 'Êtes-vous certain de vouloir réinitialiser cette entrée?',
                skip_step: 'Passer l\'étape',
                reset_step: 'Réinitialiser l\'étape',
                skip_step_question: 'Êtes-vous certain de vouloir faire passer cette étape à cet utilisateur?',
                reset_step_question: 'Êtes-vous certain de vouloir réinitialiser l\'étape de cet utilisateur?',
                no_entries: 'Aucune entrée n\'a été trouvée',
                no_ads: 'Aucune annonce n\'a été assignée',
                no_vehicle_selected: 'Aucun véhicule sélectionné',
                has_paid: 'A payé pour le service',
                has_bought: 'A acheté sa voiture',
                isActive : 'Est active ? ',
                adjustSalary : 'Salaire',
                firstName : 'Prénom',
                lastName : 'Nom de famille',
                description : 'Description en francais',
                description_en : 'Description en anglais',
                maxTravelTime : 'Distance maximum',
                zipcode : 'Code postal',
                phoneNumber : 'Numero de téléphone',
                email : 'Adresse E-mail',
                inspectorRole : `Role de l'inspecteur`,
                modificationButton : 'Modifier',
                unavailableDates: 'Dates indisponibles'
            },
            search: {
                title: 'Recherche'
            },
            tabs: {
                sequences: 'Séquences',
                ads: 'Annonces'
            },
            ads: {
                modal: {
                    title: 'Annonce',
                    site: 'Site',
                    url: 'Url',
                    description: 'Description',
                    valid: 'Cette annonce est valide.',
                    invalid: 'Cette annonce est invalide.',
                    price: 'Prix',
                    thumbnail: 'Image mise de l\'avant',
                    year: 'Année',
                    version: 'Version',
                    mileage: 'Kilométrage',
                    published: 'Publiée',
                    buying_budget: 'Budget d\'achat',
                    taxes: 'Taxes',
                    warranty: 'Garanties',
                    yearly_budget: 'Budget annuel',
                    licenses: 'Permis et Immatriculation',
                    maintenance: 'Maintenance',
                    fuel: 'Essence',
                    insurance: 'Assurance',
                    tax_dealer: 'TPS + TVQ',
                    tax_person: 'TPS ',
                    receive_announcement: 'Recevoir des annonces',
                    research_announcement: 'La recherche est basée sur tes modèles favoris et les critères ajoutés dans la page Mes critères.',
                    ready_to_receive: 'Nous avons besoin de quelques informations supplémentaires afin de lancer la recherche. Remplis les champs suivants.'

                }
            }
        },
        inspectorsSummary: {
            title: "Sommaire des inspecteurs",
            inspectionCount: "Nombre d'inspections",
            adjustment: "Ajustement",
            total: "Total à payer",
            adjust: "Ajuster",
            reset: "Réinitialiser",
            activeInspectors: "Inspecteurs actifs",
            inactiveInspectors: "Inspecteurs inactifs",
            unavailableInspectors: "Inspecteurs indisponibles",
            unavailableInspectorMessage: "Aucun inspecteur indisponible",
            startDate:"Date de début",
            endDate:"Date de fin",
            generateData: "Générer des données",
            dataAnalysis:"Analyse des données",
            type: "Type",
            client: "Client",
            chooseStartDate: 'Choisissez la date de début',
            chooseEndDate: 'Choisissez la date de fin'
        },
        ads: {
            title: 'Annonces',
            creation: 'Création'
        },
        settings: {
            title: 'Paramètres',
            default_sequence: 'Séquence par défaut',
            vehicle_update_button: 'LeParking - Mise à jour des véhicules',
            vehicle_update_message: 'Êtes-vous certain de vouloir activer la mise à jour? Cette action est TRÈS coûteuse. (temps + $)',
            vehicle_update_confirmation: 'Êtes-vous certain?',
            vehicle_update_confirmation_second: 'Une autre fois pour la forme. Êtes-vous certain certain?'
        },
        reportModal: {
            title: "Signaler un problème",
            noResponse: "Vendeur ne répond pas",
            contactLater: "Vendeur me recontacte plus tard",
            rescheduleInspection: "Vendeur souhaite décaler la date d'inspection",
            other: "Autre",
            alreadySold: "Voiture déjà vendue",
            sellerRefusal: "Refus du vendeur de faire inspecter",
            clientRequest: "Demande du client",
            back: 'Retour',
            otherInput: 'Précisez le problème que vous rencontrez svp',
            confirm: 'Confirmer',
            createInspectionCredit: 'Créer un crédit d\'inspection',
        },
        removeInspectorModal: {
            title: 'Voulez-vous vraiment retirer l\'inspection à cet inspecteur?',
            reason: 'Quelle est la raison du retrait? Elle sera envoyée à l\'inspecteur.',
        },
    },
    dashboard: {
        favorites: 'Ma sélection',
        create_own_favorite: 'À toi de créer ta liste de modèles préférés !',
        search_specific_model: 'Recherche des véhicules spécifiques, supprime ou ajoute des modèles !',
        search_best: 'Une fois ta liste de 3 modèles maximum choisis, clique sur "Rechercher les meilleures annonces",',
        find_the_best_market: 'pour que Kiwiz aille dénicher les meilleures affaires sur le marché !',
        search_best_button: 'Rechercher les meilleures annonces',
        no_favorite: 'Tu n\'as aucun favori, choisis-en et lance ta recherche!',
        vehicle_suggestion: 'Tu ne sais pas quels véhicules sont faits pour toi? Découvre-les en utilisant notre',
        recommendation_algorithm: 'algorithme de recommendation',
        next_search: 'Ta prochaine recherche pourra être effectuée dans : ',
        hour: 'heure',
        searchPlaceholder: 'Rechercher un véhicule',
        completedSelection: {
            title: 'Ta liste est pleine!',
            content: 'Tu peux maintenant lancer la recherche d\'annonces.',
            tooManyCars: 'Tu ne peux ajouter que 3 modèles à ta sélection. Modifie-la ou lance la recherche.',
        },
        email: {
            title: 'Entre ton adresse courriel',
            invalid: 'Adresse courriel invalide',
        },
        zipCode: {
            title: 'Entre ton code postal',
            invalid: 'Code postal non valide',
        },
        gearbox: {
            title: 'Boîte de vitesses',
            none: 'Aucune préférence',
            automatic: 'Automatique',
            manual: 'Manuelle'
        },
        fuelType: {
            title: 'Type d\'essence',
            none: 'Aucune préférence',
            essence: 'Essence',
            diesel: 'Diesel',
            hybride: 'Hybride',
            electrique: 'Électrique',
            ethanol: 'Ethanol',
        },
        distance: {
            title: 'Rayon de recherche'
        },
        minYear: {
            title: 'Année minimale'
        },
        maxMileage: {
            title: 'Kilométrage maximal'
        },
        conditions: 'J\'accepte les |conditions générales| et les |politiques de confidentialité| de Kiwiz.',
        launchSearch: 'Lancer la recherche',
        validation: {
            title: 'Félicitations!',
            body: 'Tu recevras les meilleures annonces sous peu par courriel.\n\nPsst : Il se peut que le courriel arrive dans les promotions ou les SPAMS.',
        }
    },
    stripe: {
        method: 'Méthodes de paiement',
        thank_you: 'Merci',
        validate_inspection: 'Dès qu\'un inspecteur de notre réseau valide l\'inspection, on te revient avec la date et l\'heure de celle-ci.',
        amount_to_pay: 'Montant payé :',
        left_to_pay: 'Reste à payer :',
        the: 'le',
        summary_payment: 'Résumé de l\'achat : ',
        payment_made: 'Paiement effectué!',

        success: {
            title: 'Paiement réussi. Merci pour ta confiance!',
        },
        failure: {
            title: 'Nous n\'avons pas pu procéder au paiement. Votre inspection est annulée.'
        },
        return: 'Retour au site web'
    },
    inspection: {
        fields: {
            constantAccelerationRoadTest: 'Montée en régime normale à chaud avec une accélération constante et linéaire',
            normalPressureTempRoadTest: 'Montée en température et en pression normale du moteur',
            goodLineKeeping: 'Bonne tenue de la ligne de conduite (lors des accélérations et freinages)',
            directionProperFunctioning: 'Bon fonctionnement de la direction (absence de jeu et de vibrations)',
            smoothGearShift: 'Passage de vitesses aisés et absence d\'accros au niveau des synchros de boite',
            goodClutch: 'Bon grip de l\'embrayage ou absence de patinage excessif du convertisseur de couple',
            goodSuspension: 'Absence d\'anomalie au niveau des trains roulants (suspensions, amortisseurs, rotules, etc.)',
            engineVibration: 'Absence de vibrations moteurs anormales',
            brakeNoice: 'Absence de vibrations et de bruits anormaux lors du freinage',
            summerFloorMats: 'État tapis d\'été',
            winterFlootMats: 'État du tapis d\'hiver',
            overallCleanliness: 'La propreté globale de l\'intérieur',
            headliner: 'Tâches au ciel de toit',
            frontSeats: 'Tâches aux sièges avant',
            backSeats: 'Tâches à la banquette arrière ',
            insideTrunk: 'Tâches ou des débris dans le coffre',
            trunkLiner: 'Des marques de rouille aux fond de coffre',
            floor: 'État du plancher',
            thicknessPaint: 'Épaisseur de la peinture',
            frontBumper: 'État du parechoc avant',
            backBumper: 'État du parechoc arrière',
            frontWindshieldWiperEsthetic: 'État des essuie-glaces avant',
            backWindshieldWiperEsthetic: 'État des essuie-glaces arrière',
            driverFrontOpticalBlock: 'État du bloc optique avant côté conducteur',
            driverBackOpticalBlock: 'État du bloc optique arrière côté conducteur',
            passengerFrontOpticalBlock: 'État du bloc optique avant côté passager',
            passengerBackOpticalBlock: 'État du bloc optique arrière côté passager',
            driverMirrorEsthetic: 'État du miroir avant côté conducteur',
            passengerMirrorEsthetic: 'État du miroir avant côté passager',
            driverBackWing: 'État de l\'aile arrière côté conducteur',
            driverFrontWing: 'État de l\'aile avant côté conducteur',
            passengerBackWing: 'État de l\'aile arrière côté passager',
            passengerFrontWing: 'État de l\'aile avant côté passager',
            driverFrontDoor: 'État de la portière avant côté conducteur',
            driverBackDoor: 'État de la portière arrière côté conducteur',
            passengerFrontDoor: 'État de la portière avant côté passager',
            passengerBackDoor: 'État de la portière arrière côté passager',
            driverUnderBody: 'État du bas de caisse côté conducteur',
            passengerUnderBody: 'État du bas de caisse côté passager',
            driverFrontRim: 'État de la jante avant côté conducteur',
            driverBackRim: 'État de la jante arrière côté conducteur',
            passengerFrontRim: 'État de la jante avant côté passager',
            passengerBackRim: 'État de la jante arrière côté passager',
            hood: 'État du capot',
            trunk: 'État du coffre',
            roof: 'État du toit',
            odor: 'Odeur',
            safetyBelt: 'Ceintures de sécurité',
            frontSeatsSettings: 'Réglages des sièges avants',
            audioSystem: 'Système audio et les hauts parleurs',
            onboardComputer: 'Ordinateur de bord',
            frontWindshieldWiper: 'Essuie-glaces avants',
            backWindshieldWiper: 'Essuie-glaces arrières',
            airConditioning: 'Climatisation',
            electricWindows: 'Vitres électriques',
            driverMirror: 'Miroir côté conducteur',
            interiorMirror: 'Miroir intérieur',
            passengerMirror: 'Miroir côté passager',
            gloveCompartment: 'Boîte à gants',
            trunkOpening: 'Ouverture du coffre',
            frontWindshield: 'État du pare-brise avant',
            backWindshield: 'État de la lunette arrière',
            driverBackDoorOpening: 'Ouverture de la portière arrière côté conducteur',
            driverFrontDoorOpening: 'Ouverture de la portière avant côté conducteur',
            passengerFrontDoorOpening: 'Ouverture de la portière avant côté passager',
            passengerBackDoorOpening: 'Ouverture de la portière arrière côté passager',
            driverBackBrake: 'État des freins arrière côté conducteur',
            driverFrontBrake: 'État des freins avant côté conducteur',
            passengerFrontBrake: 'État des freins avant côté passager',
            passengerBackBrake: 'État des freins arrière côté passager',
            frontPassingLamps: 'Lumières de croisement',
            frontMainBeam: 'Lumières de route avant',
            frontHeadLights: 'Plein-phares',
            frontFogLights: 'Lumières antibrouillard',
            frontFlashingSignalLamps: 'Clignotants',
            insideLighting: 'Éclairages d\'intérieur',
            backPositionLamps: 'Lumières de position arrière',
            brakeLights: 'Lumières de freinage',
            reversingLamps: 'Lumières de marche arrière',
            licensePlateLight: 'Éclairage de la plaque d\'immatriculation',
            backFlashingSignalLamps: 'Clignotants arrière',
            accessoryBelt: 'État de la courroie de d\'accessoire',
            wiperFluidLevel: 'Niveau du liquide lave-glaces',
            brakeFluidLevel: 'Niveau du liquide de freins',
            motorOilLevel: 'Niveau d\'huile moteur',
            motorOilQuality: 'Qualité d\'huile moteur',
            driverShockAbsorber: 'État de l\'amortisseur avant coté conducteur',
            passengerShockAbsorber: 'État de l\'amortisseur avant coté passager',
            driverFrontTirePressure: 'Pression du pneu avant côté conducteur',
            passengerFrontTirePressure: 'Pression du pneu avant côté passager',
            driverBackTirePressure: 'Pression du pneu arrière côté conducteur',
            passengerBackTirePressure: 'Pression du pneu arrière côté passager',
            driverFrontTireWear: 'Témoins d\'usure du pneu avant côté conducteur',
            driverBackTireWear: 'Témoins d\'usure du pneu arrière côté conducteur',
            passengerFrontTireWear: 'Témoins d\'usure du pneu avant côté passager',
            passengerBackTireWear: 'Témoins d\'usure du pneu arrière côté passager',
            isOBDScanner: 'OBDScanner',
            driverFrontTire: "Pneu avant côté conducteur",
            driverBackTire: "Pneu arrière côté conducteur",
            passengerFrontTire: "Pneu avant côté passager",
            passengerBackTire: "Pneu arrière côté passager",
            carPlastics: "Plastiques et garnitures",
            coolantLevel: 'Niveau du liquide de refroidissement',
            Extra: "Photo",
            Extra2: "Photo ",
            Extra3: "Photo ",
            Extra4: "Photo ",



        },

        photos: {
            'Avant du véhicule': 'Avant du véhicule',
            'Arrière du véhicule': 'Arrière du véhicule',
            'Trois-quart avant côté conducteur du véhicule': 'Trois-quart avant côté conducteur du véhicule',
            'Trois-quart avant côté passager du véhicule': 'Trois-quart avant côté passager du véhicule',
            'Côté conducteur du véhicule': 'Côté conducteur du véhicule',
            'Côté passager du véhicule': 'Côté passager du véhicule',
            'Trois-quart arrière côté conducteur du véhicule': 'Trois-quart arrière côté conducteur du véhicule',
            'Trois-quart arrière côté passager du véhicule': 'Trois-quart arrière côté passager du véhicule',
            'Compartiment moteur': 'Compartiment moteur',
            'Compartiment avant': 'Compartiment avant',
            'Compartiment arrière': 'Compartiment arrière',
            'Intérieur du coffre': 'Intérieur du coffre',
            'Caméra de recul': 'Caméra de recul',
            'Système de navigation': 'Système de navigation',
            carFront: 'Avant du véhicule',
            carBack: 'Arrière du véhicule',
            carThreeQuarterFrontDriver: 'Trois-quart avant côté conducteur du véhicule',
            carThreeQuarterFrontPassenger: 'Trois-quart avant côté passager du véhicule',
            carSideDriver: 'Côté conducteur du véhicule',
            carSidePassenger: 'Côté passager du véhicule',
            carThreeQuarterBackDriver: 'Trois-quart arrière côté conducteur du véhicule',
            carThreeQuarterBackPassenger: 'Trois-quart arrière côté passager du véhicule',
            engineBay: 'Compartiment moteur',
            frontCompartment: 'Compartiment avant',
            backCompartment: 'Compartiment arrière',
            trunkCompartment: 'Intérieur du coffre',
            rearCamera: 'Caméra de recul',
            navigationSystem: 'Système de navigation',
            driverFrontTire: "Pneu avant côté conducteur",
            driverBackTire: "Pneu arrière côté conducteur",
            passengerFrontTire: "Pneu avant côté passager",
            passengerBackTire: "Pneu arrière côté passager"
        },
        comments: {
            Mesure_hors_de_la_fourchette: 'Mesure hors de la fourchette',
            suspicion_d_un_accident: 'suspicion d\'un accident',
            Compris_dans_la_fourchette: 'Compris dans la fourchette',
            aucune_preuve_d_accident: 'aucune preuve d\'accident',
            Rayures: 'Rayures',
            Excellent: 'Excellent',
            Bon: 'Bon',
            Mauvais: 'Mauvais',
            Fonctionnelle: 'Fonctionnelle',
            'Non-fonctionnelle': 'Non-fonctionnelle',
            'Non-fonctionnelles': 'Non-fonctionnelles',
            'Non-fonctionnels': 'Non-fonctionnels',
            Fonctionnels: 'Fonctionels',
            Rouille: 'Rouille',
            Fissures: 'Fissures',
            Partiellement_opaque: 'Partiellement opaque',
            Opaque: 'Opaque',
            Présence_d_humidité: 'Présence d\'humidité',
            Brisé: 'Brisé',
            Trous: 'Trous',
            'Impact(s)_présent(s)': 'Impact(s) présent(s)',
            Très_légèrement_opaque: 'Très légèrement opaque',
            Retouches_peintures: 'Retouches peintures',
            Absence_d_une_visse: 'Absence d’une vis',
            Rouille_de_surface: 'Rouille de surface',
            Bien: 'Bien',
            Peu: 'Peu',
            Aucune_odeur: 'Aucune odeur',
            Aucune: 'Aucune',
            Excellente: 'Excellente',
            Beaucoup: 'Beaucoup',
            Oui: 'Oui',
            Hiver: 'Hiver',
            Été: 'Été',
            Pneu_de_secours: 'Pneu de secours',
            Kit_de_changement_de_pneu: 'Kit de changement de pneu',
            'Kit_anti-crevaison': 'Kit anti-crevaison',
            'Crochet_d\'attelage': 'Crochet d\'attelage',
            Aucun_équipement_présent: 'Aucun équipement présent',
            Usé: 'Usé',
            'Bien_(moins_de_30-60_secondes)': 'Bien (moins de 30-60 secondes)',
            'Très_bien_(moins_de_10_secondes)': 'Très bien (moins de 10 secondes)',
            'Non_fonctionnelle_(on_l_attend_encore_depuis_mai)': 'Non fonctionnelle (on l\'attend encore depuis mai)',
            'Non-applicable': 'N/A',
            Anomalie: 'Anomalie',
            Correcte: 'Correcte',
            Insuffisante: 'Insuffisante',
            Trop_élevée: 'Trop élevée',
            Trop_élevé: 'Trop élevée',
            Non: 'Non',
            Tabac: 'Tabac',
            Animaux: 'Animaux',
            Présence_d_un_ou_plusieurs_codes: 'Présence d\'un ou plusieurs codes',
            Aucun: 'Aucun',
            Hiver_clouté: 'Hiver clouté',
            '4_saisons': '4 saisons',
            Insuffisant: 'Insuffisant',
            Bonne: 'Bonne',
            Mauvaise: 'Mauvaise',
            Aucun_tapis_inclus: 'Aucun tapis inclus',
            extra: "supplémentaire",
            extra2: "supplémentaire",
            extra3: "supplémentaire",
            extra4: "supplémentaire"


        },
    },
};
