import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { HttpHeaderIntercepter } from './interceptors/http-header-intercepter';
import { CustomTranslateLoader } from './loaders/custom-translate-loader';
import { MaterialModule } from './material.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { GoogleMapsModule } from '@angular/google-maps';

export function tokenGetter() {
  return localStorage.getItem("idToken");
}

export function getDefaultLanguage(): string {
  return localStorage.getItem('selectedLanguage') || 'fr'; // Default to 'fr' if no language is selected
}

@NgModule({
  declarations: [
    AppComponent,
    // DragDropDirective,
    // FirebaseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthenticationModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    TranslateModule.forRoot({
      defaultLanguage: getDefaultLanguage(),  
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    // AngularFireMessagingModule,
    // CKEditorModule,
    // DragDropModule,
    BrowserAnimationsModule,
    MaterialModule,
    GoogleMapsModule,
  ],
  providers: [
    // MessagingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderIntercepter,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
